import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { playVideo } from "../redux/actions";
import { Link } from "react-router-dom";
import { colors } from "../styles/globals";
import { Reveal, Tween } from 'react-gsap';
import { Waypoint } from 'react-waypoint';

const Wrap = styled.div`

  align-items: center;
  width: 100%;
  margin: 0 auto;
  justify-content: space-around;
  align-self: flex-start;
  max-width: 900px;

  &.COMERCIAL {
   
  }

  &.GAMES {
  }

  &.BRANDING {

    display: flex;
    & img {
     max-width: 500px;
     }
  }

  &.STORYTELLING {
    /* Additional styles if needed */
  }
`;

const TextHolder = styled.div`
  position: relative;
  border-top: 4px solid white;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
`;

const ImageHolder = styled.img`
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
  width: 100%;
  object-fit: cover;
  overflow: hidden;
 
`;

const LinkTo = styled(Link)`
  color: ${colors.white};
  text-decoration: none;
  position: relative;

  h4 {
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    font-style: italic;
    opacity: 1;
    margin: 5px 0 0 -3px;
  }

  h3 {
    font-size: 0.8rem;
    font-weight: 700;
    font-style: italic;
    text-transform: uppercase;
    color: ${colors.red};
    margin: 2px 0 0 -3px;
    line-height: 1em;
  }

  h3, h4 {
    transition: transform 0.8s, opacity 1.2s;
    transition-timing-function: cubic-bezier(0.1, 0.1, 0.2, 1), ease;
  }
`;

class Box extends Component {
  state = {
    activeElements: [],
  }

  handleLink = e => {
    console.log("handleLink", this.props.boxtype);
    // Uncomment if needed
    // if (!this.props.link) {
    //   e.preventDefault();
    //   this.props.playVideo(this.props.videoUrl);
    // }
  };

  contentIn = e => {
    // Placeholder for additional functionality on content in
  }

  contentOut = e => {
    // Placeholder for additional functionality on content out
  }

  render() {
    return (
      <Wrap style={{ visibility: this.props.visible }} className={this.props.boxtype}>
        <LinkTo to={this.props.link ? `/work/${this.props.link}` : "/"} onClick={this.handleLink}>
          <Reveal repeat>
            <Tween from={{ opacity: 0, y: 100, stagger: {
              each: 1,
              from: "center",
              grid: "auto",
              ease: "power2.inOut",
            }}} duration={0.7}>
              <div>
                <Waypoint onEnter={this.contentIn} onLeave={this.contentOut}>
                  <ImageHolder src={this.props.image} loading="lazy" />
                </Waypoint>
                <TextHolder>
                  <h4>{this.props.title} {this.props.client && ` - ${this.props.client}`}</h4>
                  <h3>{this.props.category} {this.props.country && ` - ${this.props.country}`}</h3>
                </TextHolder>
              </div>
            </Tween>
          </Reveal>
        </LinkTo>
      </Wrap>
    );
  }
}

export default connect(
  null,
  { playVideo }
)(Box);
