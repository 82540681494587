import React, { Component } from "react";
import styled from "styled-components";
import Box from "./Box";

class Grid extends Component {
  state = {
    column: "1fr",
  };

  getRandomBrandingItems = (data, type, limit) => {
    const brandingItems = data.filter((item) => item.acf.category.toString() === type.toString());

    // Shuffle the brandingItems array randomly
    let currentIndex = brandingItems.length,
      randomIndex;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // Swap elements to shuffle
      [brandingItems[currentIndex], brandingItems[randomIndex]] = [
        brandingItems[randomIndex],
        brandingItems[currentIndex],
      ];
    }

    // Take only the first 'limit' items as we need only 9 results
    return brandingItems.slice(0, limit);
  };

  renderBoxes = () => {
    const { language, data, gridType } = this.props;
    let type = gridType;

    // Get only 9 randomly chosen branding items
    const brandingResults = this.getRandomBrandingItems(data, type, 10);

    return brandingResults.map((item) => {
      const {
        avatar_picture: { url },
        nombre_del_proyecto,
        project_name,
        categoria,
        category,
        cliente,
        boxtype,
        pais,
        country,
      } = item.acf;

      return (
        <Box
          boxtype={gridType}
          key={item.id}
          pais={pais}
          country={country}
          image={url}
          title={language === "es" ? nombre_del_proyecto : project_name}
          countryname={language === "es" ? pais : country}
          category={language === "es" ? categoria : category}
          client={cliente}
          videoUrl={item.acf.vimeourl}
          link={item.slug}
        />
      );
    });
  };

  render() {
    const Wrap = styled.div`
      
      justify-content: space-around;
      display: grid;
      margin: 0 auto;
      padding: 20px;
      width: 100vw;
      height:auto;
      position:relative;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

      &.STORYTELLING {
        grid-template-columns: repeat(1, minmax(100px, 1fr));
      }
      &.INTERACTIVE {
        grid-template-columns: repeat(2, minmax(100px, 1fr));
        padding: 10px;
        gap:10px;
      }
      &.BRANDING {
        grid-template-columns: repeat(2, minmax(100px, 1fr));
        padding: 10px;
        gap:10px;
      }
        
      &.COMERCIAL {
        grid-template-columns: repeat(1, minmax(100px, 1fr));
        padding: 10px;
        gap:10px;
      }

      &.GAMES {
             grid-template-columns: repeat(2, minmax(100px, 1fr));
        padding: 10px;
        gap:10px;
      }


    `;

    return <Wrap className={this.props.gridType}>{this.renderBoxes()}</Wrap>;
  }
}

export default Grid;
