import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Waypoint } from 'react-waypoint';
import * as THREE from 'three';

const ContainerDiamond = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
  width:100vw;
`;

class RotatingDiamond extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      rotationEnabled: false,
      currentColor: new THREE.Color(),
      targetColor: new THREE.Color(),
      colorTransition: 0,
    };
    this.colorInterval = null; // Initialize color interval
    this.mount = React.createRef(); // Create a reference for the mount element

    this.primitiveOptions = [
      new THREE.OctahedronGeometry(3),
      new THREE.IcosahedronGeometry(3),
      new THREE.TorusKnotGeometry(1.5, 0.4 * 1.5, 100, 16),
      new THREE.DodecahedronGeometry(3),
      new THREE.BoxGeometry(3, 2, 4),  // Add BoxGeometry for a cube
      new THREE.ConeGeometry(3,3,10),  // Add ConeGeometry for a pyramid
    ];

    // All shapes are available initially
    this.availableShapes = [...this.primitiveOptions];
  }

  getRandomShape() {
    // If no shapes are available, refill the list
    if (this.availableShapes.length === 0) {
      this.availableShapes = [...this.primitiveOptions];
    }

    // Randomly choose an index
    const randomIndex = Math.floor(Math.random() * this.availableShapes.length);

    // Remove the shape from availableShapes and return it
    return this.availableShapes.splice(randomIndex, 1)[0];
  }

  componentDidMount() {
    this.scene = new THREE.Scene();

    const aspectRatio = this.mount.current.clientWidth / this.mount.current.clientHeight;

    this.camera = new THREE.PerspectiveCamera(75, aspectRatio, 0.1, 1000);
    this.camera.position.z = 5;

    this.renderer = new THREE.WebGLRenderer({ alpha: true }); // Enable transparency
    this.renderer.setSize(this.mount.current.clientWidth, this.mount.current.clientHeight);
    this.mount.current.appendChild(this.renderer.domElement);

    window.addEventListener('resize', this.handleResize); // Handle window resize events

    const randomOption = this.getRandomShape();

    this.shape = new THREE.Mesh(randomOption, new THREE.MeshPhongMaterial({ color: this.state.currentColor }));
    this.scene.add(this.shape);

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    this.scene.add(ambientLight);

    const keyLight = new THREE.DirectionalLight(0xff0000, 0.8);
    keyLight.position.set(1, 0.5, 1);
    this.scene.add(keyLight);

    const fillLight = new THREE.DirectionalLight(0x0000ff, 0.5);
    fillLight.position.set(-1, -0.5, -1);
    this.scene.add(fillLight);

    // Initialize colors and start color transition interval
    this.updateColor();
    this.colorInterval = setInterval(this.updateColor, 10000);

    this.start();
  }

  componentWillUnmount() {
    this.stop();
    this.mount.current.removeChild(this.renderer.domElement);

    // Clear color interval
    clearInterval(this.colorInterval);

    window.removeEventListener('resize', this.handleResize); // Stop listening to resize events
  }

  // Handle window resize events
  handleResize = () => {
    const aspectRatio = this.mount.current.clientWidth / this.mount.current.clientHeight;

    this.renderer.setSize(this.mount.current.clientWidth, this.mount.current.clientHeight);
    this.camera.aspect = aspectRatio;
    this.camera.updateProjectionMatrix();
  };

  start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate);
    }
  };

  stop = () => {
    cancelAnimationFrame(this.frameId);
  };

  animate = () => {
    if (this.state.rotationEnabled) {
      this.shape.rotation.y += 0.01;
    }

    // Update color transition
    const { currentColor, targetColor, colorTransition } = this.state;
    const newColor = currentColor.clone().lerp(targetColor, colorTransition);
    this.shape.material.color = newColor;
    this.setState(({ colorTransition }) => ({
      colorTransition: Math.min(colorTransition + 0.01, 1),
    }));

    this.renderer.render(this.scene, this.camera);
    this.frameId = window.requestAnimationFrame(this.animate);
  };

  handleEnter = () => {
    this.setState({ visible: true }, () => {
      if (this.state.visible) {
        this.setState({ rotationEnabled: true });
      }
    });
  };

  handleLeave = () => {
    this.setState({ rotationEnabled: false });
  };

  // Generate random color and update the state
  updateColor = () => {
    this.setState({
      currentColor: this.state.targetColor.clone(),
      targetColor: new THREE.Color('#' + Math.floor(Math.random() * 16777215).toString(16)),
      colorTransition: 0,
    });
  };

  render() {
    return (
      <ContainerDiamond>
        <Waypoint onEnter={this.handleEnter} onLeave={this.handleLeave}>
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            ref={this.mount}
          />
        </Waypoint>
      </ContainerDiamond>
    );
  }
}

export default connect()(RotatingDiamond);
