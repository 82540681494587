const Translations = {
  main: {
    contact: {
      es: "contáctanos",
      en: "contact us"
    },

  },
  header: {
    home: {
      es: "Home",
      en: "Home"
    },

    shop: {
      es: "Tienda",
      en: "Shop"
    },

    services: {
      es: "Servicios",
      en: "Services"
    },

    reel: {
      es: "Reel",
      en: "Reel"
    },
    graphics: {
      en: "Graphics",
      es: "Gráficas"
    },
    works: {
      en: "Works",
      es: "Trabajos"
    },
    about: {
      en: "About",
      es: "Nosotros"
    },
    contact: {
      en: "Contact",
      es: "Contacto"
    }
  },
  home: {
    link: {
      en: "See all the works",
      es: "Ver todos los trabajos"
    }, 
     agency: {
      es: "We are a full-service trans media agency, creating stories, designing and building beautiful digital products, brands, and experiences. For a better world.",
      en: "Somos una agencia transmedia de servicio completo, inventamos historias , diseñamos y creamos hermosos productos, marcas y experiencias digitales. Por un mundo mejor"
    },

  },
  footer: {
    top: {
      en: "Back to top",
      es: "Volver arriba"
    }
  },
  about: {
    prizes: {
      en: "Awards & Festivals",
      es: "Premios y Festivales"
    }
  },
  item: {
    client: {
      en: "Client",
      es: "Cliente"
    }
  }
};

export default Translations;
