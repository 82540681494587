import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
//import {Route, Link, Routes, useLocation} from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
// import translations from "../translations";
import WhatsAppIcon from "../icons/whatsapp-icon.svg";
import { colors } from "../styles/globals";
import ReactPlayer from "react-player";


const  PlayerHolder = styled.div`
  top:0;
  left:0;
  rigth:0;
  width:100%;
  margin:0;
  padding:0;
  max-height:60vh;
  overflow:hidden;
`;


const Links = styled.div`
padding-top:3rem;
padding: 3rem 2rem rem 1rem; 
font-size: 2;
@media (max-width: 740px) {
    flex-direction: row;
    text-align: left;
    padding-top:1rem;
    font-size: 1;
  }
`;

const Icons = styled.div`
  align-self: center;
  padding-bottom: 1.5rem;
  
`;

const Image = styled.img` 
  transform: translate(-10px,8px);
`;

const Mailto = styled.a`
  margin-top:3rem;
  text-decoration: none;
  color: #ffffff;
  padding: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 2rem;
  text-align:center;
  @media (max-width: 400px) {

    font-size: 2rem;
  }

  `;

  const Content = styled.div`
  align-items: center;
  text-align:center;
  color: #ffffff;
  
  background: ${colors.black}
  a {
    color: white;
    text-decoration:none;
    display: block;
    font-weigth: 750;
  }
`;
const Title = styled.div`
align-self: center;
text-align:left;
width: 100%;
text-align: center;
margin: 1rem auto;
position: absolute;
top:50%;
left:0;
padding: 10px;
selectable:false;
z-index:20;
color: #ffffff;
background: ${colors.violet};
display:flex;
font-size: 1rem;
justify-content:space-between;
display: inline;
@media (min-width: 400px) {

  font-size: 1.2rem;
}

@media (min-width: 940px) {
  font-size: 1.5rem;

}
@media (min-width: 1340px) {
  font-size: 2rem;

}
font-weight: 700;
line-height: 1.5;
color: #fff;

}

`;




const smoothScroll = () => {
  const scrollY = window.scrollY;
  if (scrollY > 0) {
    setTimeout(() => {
      window.scrollTo(0, scrollY - 30 >= 0 ? window.scrollY - 30 : 0);
      smoothScroll();
    }, 10);
  }
};

const  _handleWaypointEnterMain = e => {
  smoothScroll()
  //console.log ("_handleWaypointEnterMain", e);
};


const Contact = props => (

  <Waypoint
    onEnter={_handleWaypointEnterMain}
  >
  
  <Content>
  <Mailto
         href={
           "mailto:hola@lafabulosa.org?subject=Contacto%20desde%20lafabulosa"
         }
       >
   <PlayerHolder>
   <Title>
      { props.language === "es"
          ? props.dataContact.frase_contacto
          : props.dataContact.contact_phrase
        }
    </Title>
  
    
      
      <ReactPlayer
            url={"https://vimeo.com/593952509"}
            playing={true}
            autoPlay
            muted
            width="100%"
            height="60vh"
          />
  </PlayerHolder>


    
    <Links>
  
       <h2>   
        hola@lafabulosa.org
       </h2>   
     
     </Links>
     </Mailto>
    {/*  <ContactForm></ContactForm>
 */}
    <Icons>
    <a href="tel:+56930965485" target="_blank" rel="noopener noreferrer">
    <Image
     src={WhatsAppIcon}
     alt="whatsapp"
     width="28"
     height="28"
   />

   +56 9 3096 5485
  </a>
 </Icons> 

  </Content>

  </Waypoint>
);


const mapStateToProps = state => {
  return {
    data: state.data.posts,
    language: state.data.language,
    dataContact: state.data.pages[0].acf
  };
};

export default connect(mapStateToProps)(Contact);
