import React, { Component } from 'react';
import * as THREE from 'three';

class CanvasThree extends Component {
  componentDidMount() {
    window.addEventListener('scroll', this.handleScrollEffects);
    window.addEventListener('resize', this.handleResize);
    const width = this.mount.clientWidth;
    const height = this.mount.clientHeight;

    // Initialize Scene, Camera, and Renderer
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 2000);
    this.camera.position.z = 800;
    this.renderer = new THREE.WebGLRenderer({ alpha: true });
    this.renderer.setClearColor('#000000');
    this.renderer.setSize(width, height);
    this.mount.appendChild(this.renderer.domElement);

    // Add Ambient and Directional Light for Metallic Effect
    this.scene.add(new THREE.AmbientLight(0x404040, 0.8));
    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(5, 5, 5);
    this.scene.add(directionalLight);

    // Initialize Stars, Blobs, and Primitives
    this.stars = new THREE.Object3D();
    this.movingObjects = [];
    this.scene.add(this.stars);
    this.addStars();
    this.addPlanets();
    this.addMetallicBlobs();

    // Start Animation and Generative Module
    this.start();
    this.startGenerativeModule();
  }

  // Helper function for random position
  getRandomPosition(range = 1500) {
    return Math.random() * range - range / 2; // Random value within -range/2 to range/2
  }

  // Add Stars with randomized positions
  addStars() {
    const starMaterial = new THREE.MeshBasicMaterial({ color: 0xaaaaaa });
    for (let i = 0; i < 500; i++) {
      const starGeometry = new THREE.SphereGeometry(2, 8, 8);
      const star = new THREE.Mesh(starGeometry, starMaterial);
      star.position.set(
        this.getRandomPosition(2000),
        this.getRandomPosition(2000),
        this.getRandomPosition(1000)
      );
      this.stars.add(star);
    }
  }

  // Add Planets with metallic appearance
  addPlanets() {
    const planets = [
      { color: 0xaaaaaa, size: 150 },
      { color: 0x777777, size: 70 },
      { color: 0x555555, size: 90 },
      { color: 0x666666, size: 120 },
      { color: 0x888888, size: 30 },
      { color: 0x999999, size: 100 },
      { color: 0x444444, size: 80 }
    ];

    planets.forEach(({ color, size }) => {
      const planet = new THREE.Mesh(
        new THREE.SphereGeometry(size, 32, 16),
        new THREE.MeshStandardMaterial({ color, metalness: 0.7, roughness: 0.3 })
      );
      planet.position.set(
        this.getRandomPosition(1500),
        this.getRandomPosition(1500),
        this.getRandomPosition(1000)
      );
      this.scene.add(planet);
    });
  }

  // Add metallic blobs with random sizes and positions
  addMetallicBlobs() {
    this.blobs = new THREE.Object3D();

    for (let i = 0; i < 10; i++) {
      const size = Math.random() * 40 + 20; // Random size between 20 and 60
      const color = Math.floor(Math.random() * 0x555555 + 0xaaaaaa); // Subdued metallic color
      const blobGeometry = new THREE.SphereGeometry(size, 32, 32);
      const blobMaterial = new THREE.MeshStandardMaterial({ color, metalness: 0.8, roughness: 0.2 });
      const blob = new THREE.Mesh(blobGeometry, blobMaterial);
      
      // Completely random position within a specified range
      blob.position.set(
        this.getRandomPosition(1500),
        this.getRandomPosition(1500),
        this.getRandomPosition(1000)
      );

      this.blobs.add(blob);
    }
    
    this.scene.add(this.blobs);
  }

  // Create random shapes with metallic material
  startGenerativeModule() {
    this.generativeInterval = setInterval(() => {
      const newObject = this.createRandomShape();

      // Add to moving objects if dynamic
      if (newObject.userData.isDynamic) {
        this.movingObjects.push(newObject);
      }

      // Remove after 10 seconds
      if (!newObject.userData.isStatic) {
        setTimeout(() => {
          this.scene.remove(newObject);
          newObject.geometry.dispose();
          newObject.material.dispose();
          this.movingObjects = this.movingObjects.filter(obj => obj !== newObject);
        }, 10000);
      }
    }, 3000); // Add a new shape every 3 seconds
  }

  createRandomShape() {
    const shapes = ['sphere', 'cube', 'cone', 'cylinder'];
    const shapeType = shapes[Math.floor(Math.random() * shapes.length)];

    const isStatic = Math.random() < 0.5;
    const isDynamic = !isStatic;

    const size = Math.random() * 60 + 20;
    const color = Math.floor(Math.random() * 0x555555 + 0xaaaaaa); // Subdued metallic color
    const position = {
      x: this.getRandomPosition(1500),
      y: this.getRandomPosition(1500),
      z: this.getRandomPosition(1000)
    };

    let geometry;
    switch (shapeType) {
      case 'cube':
        geometry = new THREE.BoxGeometry(size, size, size);
        break;
      case 'cone':
        geometry = new THREE.ConeGeometry(size / 2, size, 16);
        break;
      case 'cylinder':
        geometry = new THREE.CylinderGeometry(size / 2, size / 2, size, 16);
        break;
      default:
        geometry = new THREE.SphereGeometry(size, 16, 16);
    }

    const material = new THREE.MeshStandardMaterial({ color, metalness: 0.8, roughness: 0.2 });
    const shape = new THREE.Mesh(geometry, material);
    shape.position.set(position.x, position.y, position.z);
    shape.userData = { isStatic, isDynamic };
    this.scene.add(shape);
    return shape;
  }

  handleResize = () => {
    const width = this.mount.clientWidth;
    const height = this.mount.clientHeight;
    this.renderer.setSize(width, height);
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
  };

  // Handle scroll effects for subtle rotation and parallax effect
  handleScrollEffects = () => {
    const scrollPosition = window.scrollY;
    
    // Subtle rotation effect
    this.scene.rotation.y = scrollPosition * 0.0005; // More subtle rotation
    this.scene.rotation.x = scrollPosition * 0.0003;

    // Parallax effect on objects
    this.movingObjects.forEach((obj, index) => {
      obj.position.z += Math.sin(scrollPosition * 0.001 + index) * 0.3;
      obj.position.y += Math.cos(scrollPosition * 0.001 + index) * 0.3;
    });
  };

  componentWillUnmount() {
    this.stop();
    clearInterval(this.generativeInterval);
    this.mount.removeChild(this.renderer.domElement);
    window.removeEventListener('scroll', this.handleScrollEffects);
    window.removeEventListener('resize', this.handleResize);
  }

  start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate);
    }
  };

  stop = () => {
    cancelAnimationFrame(this.frameId);
  };

  animate = () => {
    this.movingObjects.forEach(obj => {
      obj.rotation.x += 0.01;
      obj.rotation.y += 0.01;
    });

    this.renderScene();
    this.frameId = window.requestAnimationFrame(this.animate);
  };

  renderScene = () => {
    this.renderer.render(this.scene, this.camera);
  };

  render() {
    return (
      <div
        style={{
          zIndex: -1,
          opacity: 0.5,
          position: 'fixed',
          top: 0,
          width: '100vw',
          height: '100vh',
          backgroundBlendMode: 'lighten',
        }}
        ref={(mount) => {
          this.mount = mount;
        }}
      />
    );
  }
}

export default CanvasThree;
